.react-datepicker__input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 38px;
}

.react-datepicker__view-calendar-icon svg {
    margin-left: 8px;
}